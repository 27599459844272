.landing-menu {
    padding: 30px 15px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99999;
    transition: all 0.4s ease-in-out;
}
.landing-menu.fixed {
    position: fixed;
    background: #fff;
    padding: 10px 15px;
    box-shadow: 0px 13px 35px -12px rgba(35, 35, 35, 0.15);
}
.landing-menu .logo {
    width: 292px;
}
.landing-menu .logo a {
    display: block;
}
.landing-banner {
    background: #faf9f7;
    padding: 250px 0 0;
    position: relative;
    z-index: 999;
}