@media (min-width: 1350px) {
    .lg-container .container {
        max-width: 1320px;
    }
}

@media (max-width: 1366px) {
    .landing-menu .logo {
        width: auto;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1170px;
    }

    .container-custom.container {
        max-width: 1200px;
    }
}

@media (min-width: 992px) {
    .container {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 1200px) and (max-width: 1450px) {}

@media (min-width: 1200px) and (max-width: 1366px) {}

@media (max-width: 1199px) {

    .landing-menu,
    .landing-menu.fixed {
        padding-left: 0;
        padding-right: 0;
    }
    .terms_and_policy .tab-content {
        padding-left: 0;
      }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .fancy-hero-one h1,
    .fancy-hero-one h3 {
        font-size: 44px;
        line-height: 1.2em;
    }

    .main-page-wrapper {
        padding-top: 99px;
    }
}

@media (max-width: 991px) {

    .md-pb-20 {
        padding-bottom: 20px !important;
    }

    .landing-banner {
        padding: 190px 0 100px;
    }

    .landing-menu.fixed {
        padding: 20px 15px;
    }

    .fancy-hero-one {
        padding: 80px 0 30px;
    }

    .fancy-hero-one h1,
    .fancy-hero-one h3 {
        font-size: 44px;
        line-height: 1.2em;
    }

    .fancy-hero-one h2 {
        font-size: 24px;
        line-height: 1.2em;
    }

    .fancy-hero-one p {
        font-size: 20px;
        line-height: 1.39em;
        font-weight: 300;
        color: #2a2a2a;
        text-align: center;
        padding: 80px 20px 0 20px;
    }

    .fancy-hero-one .page-title {
        font-size: 16px;
    }

    .fancy-hero-seven h1,
    .fancy-hero-seven h3 {
        font-size: 42px;
    }

    .fancy-hero-seven {
        padding: 150px 0 80px;
        background-position: 0 -250px;
    }

    .fancy-feature-seven {
        padding: 80px 0 80px;
    }

    .title-style-four h1,
    .title-style-four h3 {
        font-size: 40px;
        line-height: 1.2em;
    }

    .block-style-thirtyTwo {
        padding: 35px 15px 25px 10px;
    }

    .block-style-thirtyTwo .icon {
        width: 45px;
        height: 45px;
    }

    .block-style-thirtyTwo .text {
        width: calc(100% - 45px);
        padding-left: 20px;
    }

    .block-style-thirtyTwo .text h4 {
        font-size: 22px;
    }

    .fancy-short-banner-two .bg-wrapper {
        padding: 35px 30px;
        text-align: center;
    }

    .fancy-short-banner-two h1,
    .fancy-short-banner-two h3 {
        margin: 0 auto 35px;
    }

    .fancy-text-block-one .quote-wrapper {
        padding: 30px 0 0 0;
    }

    .fancy-text-block-one .quote-wrapper p {
        font-size: 18px;
    }

    .fancy-text-block-one .quote-wrapper blockquote {
        margin: 25px;
        font-size: 24px;
        text-align: center;
    }

    .fancy-text-block-one .quote-wrapper h6 {
        font-size: 16px;
        text-align: center;
    }

    .fancy-text-block-one .quote-wrapper h6 span {
        font-size: 16px;
    }

    .fancy-text-block-one img.rounded-circle {
        margin-left: auto;
        margin-right: auto;
        max-width: 50%;
        height: 180px;
    }

    .fancy-text-block-one {
        padding: 80px 0 80px;
    }

    .fancy-text-block-one:before,
    .fancy-text-block-one:after,
    .fancy-text-block-one .quote-wrapper blockquote:before,
    .contact-us-light:before {
        display: none;
    }

    .fancy-text-block-one .title-style-four {
        padding-bottom: 80px;
    }

    .contact-us-light {
        padding: 30px 0 0 0;
    }

    .contact-us-light .address-info .icon {
        height: 50px;
    }

    .contact-us-light .address-info .title {
        padding-top: 20px;
    }

    .contact-us-light .address-info p {
        font-size: 20px;
        line-height: 1.6em;
    }

    .terms_and_policy .nav-tabs {
        display: none !important;
    }

    .terms_and_policy .tab-content>.tab-pane {
        display: block;
        opacity: 1;
        margin-bottom: 60px;
    }

    .terms_and_policy {
        padding: 80px 0 0;
    }

    .terms_and_policy .tab-content h1,
    .terms_and_policy .tab-content h3, {
        font-size: 38px;
    }

    .terms_and_policy .tab-content h2 {
        font-size: 24px;
        padding: 40px 0 20px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {}

@media (max-width: 767px) {
    .landing-menu .d-flex {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}

@media (min-width: 768px) {}

@media (min-width: 576px) and (max-width: 767px) {}

@media (max-width: 575px) {
    #feature-menu {
        margin: 0;
    }

    .landing-banner {
        padding: 170px 0 0;
    }

    .fancy-hero-seven {
        padding: 150px 0 80px;
        background-position: 0 0;
    }
}

@media (max-width: 430px) {}

@media (max-width: 400px) {}