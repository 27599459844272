#feature-menu {
    padding: 0;
  }
  
  .landing-mobile_menu .nav-item .nav-link {
    font-family: "Rubik", sans-serif;
    color: var(--medium-gray);
    font-size: 18px;
    margin: 0 30px;
    padding: 0 0 5px 0;
    transition: all 0.3s ease-out;
    position: relative;
    &::before {
      position: absolute;
      content: "";
      left: 0;
      width: 0;
      height: 2px;
      background: #000;
      -webkit-transition: 0.3s;
      transition: 0.3s;
      bottom: 0;
    }
  }
  .landing-mobile_menu .nav-link.active {
    &::before {
      width: 100%;
    }
  }