.fancy-feature-seven {
    background: #ecf6ff;
    padding: 150px 0 150px;
    position: relative;
    z-index: 1;
  }
  .fancy-feature-seven:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 45px;
    background: url(../../assets/images/shape/58.svg) no-repeat top center;
    background-size: cover;
    left: 0;
    top: -42px;
  }