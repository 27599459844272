:root {
  --text-color: #73737b;
  --text-light: #888888;
  --heading: #101621;
  --blue-dark: #6f55ff;
  --red-light: #fd6a5e;
  --yellow-deep: #ffb840;
  --blue-light: #3bb0d7;
  --purple-blue: #655c97;
  --vin-red: #973c56;
  --blue-miami: #1dbee8;
  --medium-gray: #2c2c2c;
  scroll-behavior: smooth;
}

::selection {
  background-color: var(--blue-dark);
  color: #fff;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  vertical-align: baseline;
  text-decoration: none;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
  padding: 0;
}

.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
  color: var(--medium-gray);
  font-weight: normal;
}

.h1,
h1 {
  font-size: 52px;
  line-height: 1.1em;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  display: inline-block;
  color: inherit;
}

a:hover {
  cursor:pointer;
 }

a:hover,
a:focus,
a:visited {
  text-decoration: none;
  outline: none;
}

.font-rubik {
  font-family: "Rubik", sans-serif !important;
}

.font-gilroy-bold {
  font-family: "gilroy-bold" !important;
}

img {
  max-width: 100%;
  display: block;
}

button {
  border: none;
  outline: none;
  box-shadow: none;
  display: block;
  padding: 0;
  cursor: pointer;
  background: transparent;
}

button:focus {
  outline: none;
}

.scroll-top {
  width: 45px;
  height: 45px;
  line-height: 45px;
  position: fixed;
  bottom: 20px;
  right: 5px;
  z-index: 99;
  text-align: center;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  border-radius: 50%;
  background: var(--blue-miami);
  transition: all 0.3s ease-in-out;
  img {
    margin: auto;
  }
}
.scroll-top:after {
  position: absolute;
  z-index: -1;
  content: "";
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 1;
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 80%);
}

body {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.2rem;
  line-height: 1.2;
  color: var(--medium-gray);
}

body a {
  text-decoration: none;
  display: inline-block;
}

.main-page-wrapper {
  overflow: hidden;
  padding: 0 !important;
}

body .theme-btn-two {
  font-family: "gilroy-semibold";
  font-size: 18px;
  color: #fff;
  line-height: 48px;
  border: 2px solid var(--blue-miami);
  background: var(--blue-miami);
  padding: 0 42px;
  margin: 50px 0 50px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}

body .theme-btn-two:hover {
  background: transparent;
  color: var(--blue-miami);
}

.title-style-four {
  padding-bottom: 50px;
}

.title-style-four h1,
.title-style-four h3 {
  font-family: "gilroy-black";
  font-size: 58px;
  line-height: 1.15em;
  color: var(--medium-gray);
}

.title-style-four span {
  position: relative;
  z-index: 1;
}

.title-style-four span img {
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

.block-style-thirtyTwo {
  background: #fff;
  border: 2px solid #000 transparent;
  border-radius: 10px;
  padding: 45px 55px 35px 30px;
  margin-top: 40px;
}

.block-style-thirtyTwo :hover:before {
  border: 2px solid #000;
}

.block-style-thirtyTwo .icon {
  width: 65px;
  height: 65px;
  border-radius: 50%;
}

.block-style-thirtyTwo .icon img {
  height: 30px;
}

.block-style-thirtyTwo .text {
  width: calc(100% - 55px);
  padding-left: 32px;
}

.block-style-thirtyTwo .text h4 {
  font-weight: 600;
  font-size: 24px;
}

.block-style-thirtyTwo .text p {
  font-size: 17px;
  line-height: 1.6em;
  padding: 22px 0;
}

.block-style-thirtyTwo .text a {
  float: right;
}

.block-style-thirtyTwo .text .theme-btn-ten {
  font-size: 15px;
  padding-bottom: 0;
}

.block-style-thirtyTwo .text .theme-btn-ten .fa {
  font-size: 10px;
  margin-left: 2px;
}

.fancy-hero-one {
  position: relative;
  z-index: 5;
  text-align: center;
  padding: 150px 0 50px;
}

.fancy-hero-one .container {
  padding-bottom: 25px;
}

.fancy-hero-one h1,
.fancy-hero-one h3 {
  font-family: "gilroy-black";
  font-size: 58px;
  line-height: 1.15em;
  color: var(--medium-gray);
  padding-bottom: 30px;
}

.fancy-hero-one h2 {
  padding: 40px 0 40px;
  font-size: 26px;
  font-weight: 200;
}

.fancy-hero-one span {
  position: relative;
  z-index: 1;
}

.fancy-hero-one span img {
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

.fancy-hero-one p {
  font-size: 28px;
  line-height: 1.39em;
  font-weight: 300;
  color: #2a2a2a;
  text-align: center;
  padding: 25px 0 45px;
}

.fancy-hero-one .search-form button:hover {
  background: var(--yellow-deep);
}

.fancy-hero-one [class*="bubble"] {
  position: absolute;
  z-index: -1;
  border-radius: 50%;
}

.fancy-hero-one [class*="bubble"]:before {
  content: "";
  border-radius: 50%;
  position: absolute;
}

.fancy-hero-one .bubble-one {
  width: 120px;
  height: 120px;
  left: 4%;
  top: 15%;
  animation: rotated 12s infinite linear;
}

.fancy-hero-one .bubble-one:before {
  width: 6px;
  height: 6px;
  background: #fac3ff;
  top: 0;
  left: 50%;
}

.fancy-hero-one .bubble-two {
  width: 160px;
  height: 160px;
  top: 30%;
  left: 15%;
  animation: rotated 17s infinite linear;
}

.fancy-hero-one .bubble-two:before {
  width: 10px;
  height: 10px;
  background: #8fe9e0;
  top: 50%;
  right: 100%;
}

.fancy-hero-one .bubble-three {
  width: 120px;
  height: 120px;
  bottom: 33%;
  left: 4%;
  animation: rotatedTwo 12s infinite linear;
}

.fancy-hero-one .bubble-three:before {
  width: 6px;
  height: 6px;
  background: #ffd5ad;
  bottom: 0;
  left: 50%;
}

.fancy-hero-one .bubble-four {
  width: 120px;
  height: 120px;
  right: 5%;
  top: 13%;
  animation: rotated 12s infinite linear;
}

.fancy-hero-one .bubble-four:before {
  width: 6px;
  height: 6px;
  background: #8fe9e1;
  top: 0;
  left: 50%;
}

.fancy-hero-one .bubble-five {
  width: 160px;
  height: 160px;
  top: 30%;
  right: 7%;
  animation: rotated 17s infinite linear;
}

.fancy-hero-one .bubble-five:before {
  width: 10px;
  height: 10px;
  background: #ffd5ad;
  top: 50%;
  right: 100%;
}

.fancy-hero-one .bubble-six {
  width: 120px;
  height: 120px;
  bottom: 33%;
  right: 4%;
  animation: rotatedTwo 12s infinite linear;
}

.fancy-hero-one .bubble-six:before {
  width: 6px;
  height: 6px;
  background: #fac3ff;
  bottom: 0;
  left: 50%;
}

.fancy-hero-seven {
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  text-align: center;
  padding: 200px 0 170px;
  background-image: url(../images/books-imagination-still-life.webp);
  background-position: 0 -564px;
}
.fancy-hero-seven:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}
.fancy-hero-seven h1,
.fancy-hero-seven h3  {
  font-size: 72px;
  font-weight: 500;
  color: #fff;
  letter-spacing: -1px;
}
.fancy-hero-seven p {
  font-size: 22px;
  color: #fff;
  line-height: 1.75em;
  padding-top: 25px;
}

.fancy-text-block-one {
  position: relative;
  z-index: 3;
  background: linear-gradient(45deg, #fffbf2, #edfffd);
  padding: 150px 0 150px 0;
}

.fancy-text-block-one:before,
.fancy-text-block-one:after {
  content: url(../images/shape/10.svg);
  position: absolute;
  opacity: 0.9;
}

.fancy-text-block-one:before {
  top: -133px;
  left: 0;
  animation: jumpTwo 4s infinite linear;
}

.fancy-text-block-one:after {
  bottom: -133px;
  right: 0;
  animation: jumpThree 4s infinite linear;
}

.fancy-text-block-one .quote-wrapper {
  padding-left: 25px;
}

.fancy-text-block-one .quote-wrapper p {
  font-family: "gilroy-semibold";
  font-size: 20px;
  color: #000000;
  padding-bottom: 21px;
}

.fancy-text-block-one .quote-wrapper p span {
  color: #ffb147;
}

.fancy-text-block-one .quote-wrapper blockquote {
  font-size: 32px;
  line-height: 1.5em;
  color: var(--medium-gray);
  position: relative;
  margin-bottom: 43px;
}

.fancy-text-block-one .quote-wrapper blockquote:before {
  content: url(../images/icon/06.svg);
  position: absolute;
  left: -92px;
  top: 11px;
}

.fancy-text-block-one .quote-wrapper h6 {
  font-size: 20px;
  font-weight: 500;
}

.fancy-text-block-one .quote-wrapper h6 span {
  font-weight: normal;
  color: #9c9c9c;
  font-size: 18px;
}

.fancy-text-block-one .quote-wrapper h6 i {
  padding-left: 5px;
}

.contact-us-light {
  background: linear-gradient(45deg, #fffbf2, #edfffd);
  position: relative;
  z-index: 1;
  padding: 80px 0 80px;
}

.contact-us-light:before {
  position: absolute;
  left: 0;
  bottom: -140px;
}

.contact-us-light .address-info {
  text-align: center;
  margin: 50px 0 50px;
}
.contact-us-light .address-info .icon {
  height: 82px;
}
.contact-us-light .address-info .icon img {
  margin: 0 auto;
  max-height: 100%;
}
.contact-us-light .address-info .title {
  font-size: 18px;
  color: #8e8e8e;
  padding: 30px 0 5px;
}
.contact-us-light .address-info p {
  font-size: 24px;
  line-height: 1.45em;
  color: #000;
}
.contact-us-light .address-info ul li a {
  font-size: 22px;
  margin: 5px 15px;
  color: rgba(0, 0, 0, 0.52);
  transition: all 0.2s ease-in-out;
}
.contact-us-light .address-info ul li a:hover {
  color: var(--blue-dark);
}
.theme-footer-eight{
  margin:50px 0 20px;
}
.theme-footer-eight .footer-about-widget ul li a {
  font-size: 18px;
  color: #343434;
  margin-bottom: 5px;
  transition: all 0.2s ease-in-out;
}
.theme-footer-eight .footer-about-widget ul li a.phone {
  font-size: 20px;
}
.theme-footer-eight .footer-about-widget ul li a:hover {
  text-decoration: underline;
}
.theme-footer-eight .footer-title {
  font-size: 24px;
  font-weight: 500;
  color: #0e0e0e;
  position: relative;
  margin: 5px 0 21px;
}
.theme-footer-eight .footer-list ul li a {
  color: #343434;
  line-height: 40px;
  transition: all 0.2s ease-in-out;
}
.theme-footer-eight .footer-list ul li a.address {
  line-height: 1.5;
  padding-top: 6px;
}
.theme-footer-eight .footer-list ul li a:hover {
  text-decoration: underline;
}
.theme-footer-eight .top-footer [class*="col-"] {
  margin-bottom: 35px;
}
.theme-footer-eight .bottom-footer p {
  font-size: 16px;
  color: #373737;
}
.theme-footer-eight .bottom-footer .social-icon a {
  font-size: 20px;
  margin-left: 15px;
  color: #323232;
}
.terms_and_policy {
  padding: 60px 0;
  border-bottom: 1px solid #ececec;
}
.terms_and_policy .sidenav-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
}
.terms_and_policy .nav-tabs {
  border: none;
  background: #f2fbfd;
  padding: 30px 30px 30px 45px;
}
.terms_and_policy .nav-item {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  margin: 0;
}
.terms_and_policy .nav-item .nav-link {
  font-family: "Rubik", sans-serif;
  font-size: 20px;
  line-height: 60px;
  padding: 0;
  background: transparent;
  border: none;
  color: #222;
  transition: all 0.2s ease-in-out;
}
.terms_and_policy .nav-item .nav-link.active {
  color: var(--blue-dark);
}
.terms_and_policy .tab-content {
  padding: 0 0 0 50px;
}
.terms_and_policy .tab-content h1,
.terms_and_policy .tab-content h3 {
  font-size: 55px;
  padding-bottom: 10px;
}
.terms_and_policy .tab-content .update-date {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.54);
  margin-bottom: 20px;
}
.terms_and_policy .tab-content h2 {
  font-family: "gilroy-semibold";
  font-size: 28px;
  padding: 50px 0 25px;
}
.terms_and_policy .tab-content p {
  line-height: 34px;
  margin-bottom: 15px;
}
.terms_and_policy .tab-content p a {
  text-decoration: underline;
  color: #000;
}
.terms_and_policy .tab-content ul {
  list-style-type: disc;
  padding-left: 18px;
}
.terms_and_policy .tab-content ul li {
  color: #000;
}
.terms_and_policy .tab-content ul li a {
  text-decoration: underline;
  color: #000;
}
.terms_and_policy .tab-content ul li {
  padding-bottom: 10px;
}
.doc-container{
  padding-top: 90px;
}
.fancy-short-banner-two .content-wrapper {
  position: relative;
}
.fancy-short-banner-two .content-wrapper:before {
  //content: url(../images/shape/12.svg);
  position: absolute;
  top: -117px;
  right: -117px;
  z-index: -1;
  opacity: 0.9;
  animation: jumpTwo 5s infinite linear;
}
.fancy-short-banner-two .bg-wrapper {
  position: relative;
  overflow: hidden;
  background: #7034ff;
  box-shadow: 0 20px 60px 0 rgba(104, 103, 255, 0.2);
  border-radius: 5px;
  padding: 50px 55px;
  z-index: 1;
}
.fancy-short-banner-two h1,
.fancy-short-banner-two h3 {
  font-size: 36px;
  line-height: 1.16em;
  color: #fff;
  max-width: 560px;
}
.fancy-short-banner-two a {
  width: 178px;
  line-height: 54px;
  background: #fff;
  border-radius: 5px;
  color: var(--blue-dark);
  font-weight: 500;
  text-align: center;
  transition: all 0.3s ease-in-out;
}
.fancy-short-banner-two a:hover {
  background: #ffba12;
  color: #fff;
}
.pt-30 {
  padding-top: 30px;
}
.pt-40 {
  padding-top: 40px;
}
.pt-90 {
  padding-top: 90px;
}
.pb-150 {
  padding-bottom: 150px;
}